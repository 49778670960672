export const headSection = {
  logo: 'here',
  topTitle: 'Learn to pass your exams the new way.',
  topSubTitle: 'Online teaching academy'
}

export const cardsData = [
  {'title': 'Daily Exam Questions', 'price': 250,
    'remote_image': 'https://i.ibb.co/3m9syxb/exam2.png',
    'desc': 'Receive daily exam preparation question, subscribe to PRO fo solutions...', 'id': 2},
  {'title': 'Live Lessons', 'price': 100,
  'remote_image': 'https://i.ibb.co/5KZkYJ2/live-lessons.png',
  'desc': 'Tune in to live lessons 3 days a week...', 'id': 1},
  {'title': 'Personal Tutor', 'price': 95,
    'remote_image': 'https://i.ibb.co/0Yp9J5R/tutor-e-learning.png',
    'desc': 'Online persional tutor to help you with your revision 1 on 1...', 'id': 3},
  ];

export const howWeHelp = {
  howText: 'We are passionate about helping clients on how they can utilise their information technology (IT)' +
    ' to optimally achieve their business goals. Let our consultants soothe your IT headache.'
}

export const blogsData = {
  'topTitle': 'Blogs and Articles',
  'headerTitle': 'Categories',
  'categories': [
      {'cat_id': 1, 'cat_name': 'GCSE', 'cat_icon': 'https://idaily-img.s3.eu-west-2.amazonaws.com/n-27u-01-02-22-22-44-47-exam-revision.jpg'},
      {'cat_id': 2, 'cat_name': 'A LEVEL', 'cat_icon': 'https://idaily-img.s3.eu-west-2.amazonaws.com/n-27u-01-02-22-22-44-47-exam-revision.jpg'},
      {'cat_id': 3, 'cat_name': 'GENERAL', 'cat_icon': 'https://idaily-img.s3.eu-west-2.amazonaws.com/n-27u-01-02-22-22-44-47-exam-revision.jpg'}]
}
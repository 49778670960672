import React, { Component } from "react";
import PrimarySearchAppBar from './PrimarySearchAppBar'
import { Switch, Route } from "react-router-dom";
import Home from './Home'
import About from './About'
import Services from './Services'
import Contact from './Contact'
import Footer from './Footer'
import Pricing from './Pricing'
import Disenroll from './Disenroll'
import DailyQuestion from './DailyQuestion'
import DailyQuestionLivePreview from './DailyQuestionLivePreview'
import ProSubscription from './ProSubscription'
import ProSubscriptionSuccess from './ProSubscriptionSuccess'
import ProSubscriptionCancel from './ProSubscriptionCancel'
import ProSubscriptionCancelSuccess from './ProSubscriptionCancelSuccess'
import Kenya from "./LandingPages/Kenya";
import NotFound from './NotFound'
import CookieConsent from "react-cookie-consent";
import Zimbabwe from "./LandingPages/Zimbabwe";
import Enrol from "./LandingPages/Enrol";
import BlogsHomeView from "./BlogsAndArticles/BlogsHomeView";
import TableOfContentsBlog from "./BlogsAndArticles/TableOfContentsBlog";
import TableOfContentsTopics from "./BlogsAndArticles/TableOfContentsTopics";
import ListOfContent from "./BlogsAndArticles/ListOfContent";
import TableOfContentsCourse from "./LandingPages/TableOfContentsCourse";
import TableOfContentsPaper from "./LandingPages/TableOfContentsPaper";
import QuestionView from "./LandingPages/QuestionView";
import PaperInsertView from "./LandingPages/PaperInsertView";
import ExamBoardView from "./LandingPages/ExamBoardsView";

import Analytics from 'react-router-ga'

class Main extends Component {
  render() {
    return (
      <div>
        <Analytics id="UA-153402610-3">
          <PrimarySearchAppBar />

          <Switch>
            <Route path='/' exact component={Home} />
            <Route path='/exam-revision/:c' exact component={Enrol} />
            <Route path='/blogs-and-articles' exact component={BlogsHomeView} />
            <Route path='/blogs-and-articles/:cat/:bid' exact component={TableOfContentsBlog} />
            <Route path='/blogs-and-articles/:cat/:bid/:subject/:sid' exact component={TableOfContentsTopics} />
            <Route path='/blogs-and-articles/:cat/:bid/:subject/:sid/:tid' exact component={ListOfContent} />
            <Route path='/blogs-and-articles/:cat/:bid/:subject/:sid/:tid/:cname/:cid' exact component={ListOfContent} />
            <Route path='/boards/:region/:course/:cid' exact component={ExamBoardView} />
            <Route path='/revision/:region/:course/:cid/:eid' exact component={TableOfContentsCourse} />
            <Route path='/revise/:region/:course/:cid/:eid/:paper/:pid' exact component={TableOfContentsPaper} />
            <Route path='/question/:paper/:qid' exact component={QuestionView} />
            <Route path='/past-papers/insert/:iid' exact component={PaperInsertView} />
            <Route path='/zimbabwe' exact component={Zimbabwe} />
            <Route path='/kenya' exact component={Kenya} />
            <Route path='/za' exact component={Kenya} />
            <Route path='/about-us' exact component={About} />
            <Route path='/services' exact component={Services} />
            <Route path='/contact' exact component={Contact} />
            <Route path='/pricing' exact component={Pricing} />
            <Route path='/disenroll/:u/:c' exact component={Disenroll}/>
            <Route path='/idaily-question/:u/:q' exact component={DailyQuestion}/>
            <Route path='/live-preview-question' exact component={DailyQuestionLivePreview}/>
            <Route path='/subscribe-to-pro/:u' exact component={ProSubscription}/>
            <Route path='/subscribe-to-pro/:u/:c' exact component={ProSubscription}/>
            <Route path='/subscribe-to-pro-success' exact component={ProSubscriptionSuccess}/>
            <Route path='/cancel-subscription/:u' exact component={ProSubscriptionCancel}/>
            <Route path='/cancel-subscription-success' exact component={ProSubscriptionCancelSuccess}/>
            <Route path='' component={NotFound}/>
          </Switch>

          <Footer />
          <CookieConsent
          style={{ background: '#1919198c' }}
          buttonStyle={{ background: '#5eaa36', color: "white", fontSize: "13px" }}
          >This website uses cookies to enhance the user experience.</CookieConsent>
        </Analytics>
      </div>
    )
  }
}

export default Main;